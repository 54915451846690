#background-blur {
    height: 100vh;
    width: 100vw;
    position: fixed;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: black;
    opacity: .7;
}

.Error-Message {
    padding: 20px;
    top: 50%;
    color: black;
    z-index: 999;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white !important;
    text-align: center;
}

.Error-Contents > h2 {
    font-weight: heavy;
    font-size: 24px;
}

.Error-Contents > p {
    width: 70%;
    margin: 0 auto;
    font-size: 16px;
}

.Error-Contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .Error-Message {
        width: 80%;
    }

    .Error-Contents > p {
        width: 100%;
    }
}