.Results-Screen {
    display: flex;
    height: calc(100vh - 4em);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.results-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    transition: all .3s ease-in-out;
}

.res {
    width: 80vw; 
    padding: 10px;
    background-color: black;
}

/* Selected Character Box */
.selected-character-box {
    border: 0.6em solid var(--black);
    min-width: 300px;
    width: 20%;
    margin-right: -.6em;
    margin-bottom: -.6em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
  
.selected-character-box .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    position: relative;
}
  
.selected-character-box .content .name-box {
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7em;
}
  
.selected-character-box .content img {
    width: 30%;
    height: auto;
}

.selection {
    display: inline-block;
    transform: none;
    margin: 5px
}

.Player-Num {
    position: relative; 
    left: 3%;
    margin-bottom: 0; 
    margin-top: 5px; 
    padding-left: 10px;
    font-size: 36px;
}

.desktop-slant {
    -webkit-clip-path: polygon(0 21%,100% 0,100% 100%,0 100%);
    clip-path: polygon(0% 21%, 100% 0, 100% 100%, 0 100%);
}
  
.char-name-border {
    width: 101%;
    height: 40px;
    background-color:black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 60%, 100% 0%, 100% 40%, 0% 100%);
    margin-bottom: -30px;
}

.Selected-Char-Name {
    color: black;
    font-size: 32px;
    padding-top: .5em;
    font-weight: normal;
}

.name-box {
    background-color: var(--beige);
    width: auto;
}

.name-box.desktop-slant {
    width: 100%;
}

/* MOBILE CSS */
@media screen and (max-width: 767px) {
    .Result-Buttons-Container{
        display: flex;
        top: 2.5em;
        position: relative;
        justify-content: space-evenly;
    }

    .Results-Mobile-Screen {
        position: relative;
    }

    #results-screen {
        width: 100%;
        margin-bottom: 200px;
    }

    .results-container {
        margin: 0 auto;
        display: inline;
    }

    .Player-Num {
        color: black;
        margin: auto;
        left: 0;
    }

    .Selected-Char {
        display: flex;
        justify-content: space-between;
    }

    .Selected-Char > .parallelogram.drop-shadow {
        margin-right: -25px;
        padding-top: 20px;
    }

    .selected-character-box {
        width: 300px;
    }

    .selected-character-box .content .name-box {
        width: 100%;
        height: 100;
        margin-top: -1px;
    }

    .selected-character-box, .selected-character-box .content{
        flex-direction: row;
    }

    .mobile-slant {
        display: block;
    }

    .Selected-Char-Name {
        padding: 10px;
        font-size: 18px;
        text-align: center;
    }

    .selected-character-box .content img {
       padding: 0 20px
    }

    .mobile-slant {
        background-color: black;
        width: 20px !important
    }

}

/* TABLET CSS */
@media screen and (min-width: 768px) and (max-width: 1645px) {

    .selected-character-box .content .name-box {
        height: 5em;
    }

    .selected-character-box {
        min-width: 200px;
        width: 10%;
    }
    .name-box.desktop-slant {
        text-align: center;
    }

    .desktop-slant {
        clip-path: polygon(0% 30%, 100% 0, 100% 100%, 0 100%);
    }

    .Selected-Char-Name {
        font-size: 18px;
    }

    .Result-Buttons-Container{
        top: 2em;
        position: relative;
    }
}