:root {
  --golden: #ffc452;
  --black: #000000;
  --grey: #bbbbbb;
  --white: #ffffff;
  --red: #c31616;
  --orange: #d65e22;
  --yellow: #d8a410;
  --green: #079728;
  --blue: #356bee;
  --purple: #562dae;
  --pink: #d7307d;
  --cyan: #1aa7d5;
  --beige: #FFC452;
}

@font-face {
  font-family: "Urbanist";
  src: url("fonts/Urbanist-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("fonts/Urbanist-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Urbanist";
  src: url("fonts/Urbanist-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("fonts/Urbanist-BoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

body {
  color: var(--white);
  margin: 0;
  padding:0;
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282828;
  height: 100vh;
  max-width: 100vw;
  min-width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.uppercase {
  text-transform: uppercase;
}

h1 {
  font-style: italic;
  font-weight: 900;
  font-size: 4em;
  text-transform: uppercase;
}

.outline {
  -webkit-text-stroke: 0.05em var(--black);
  text-shadow: -0.1em 0.1em 0px var(--black);
  margin: 30px;
}

.drop-shadow {
  box-shadow: -0.1em 0.1em 0 var(--black);
}

.extrabold {
  font-weight: 900;
}

.italic {
  font-style: italic;
}

.button {
  margin: .8em;
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  display: inline;
  color: var(--black);
  padding: 0.5rem 2rem;
  text-decoration: none;
  background-color: var(--white);
  transition: all .08s ease-out;
  font-size: 1.2em;
  cursor: pointer;
}

.button * {
  margin-top: 0;
  margin-bottom: 0;
}

.button.button-golden {
  background-color: var(--golden);
}

.button.button-red {
  background-color: var(--red);
}

.button.button-green {
  background-color: var(--green);
}

.button:hover {
  border: 0.2em solid var(--orange);
  color: var(--orange);
}

.button.button-green, .button.button-red {
  color: var(--white);
}

.button.button-green:hover, .button.button-red:hover {
  border: 0.2em solid var(--orange);
  color: var(--white);
}

.button.dropdown-button select {
  background-color: transparent;
  padding: 0.5em 3.5em 0.5em 1em;
  width: 100%;
  border: none;
  margin: 0;
  outline: none;
}

.button.dropdown-button select:active, .button.dropdown-button select:focus-visible, .button.dropdown-button select:focus {
  outline: none;
}

.parallelogram {
  transform: skew(-8deg);
}

.parallelogram > * {
  display: inline-block;
  transform: skew(8deg);
}

.center.parallelogram {
  transform: translate(-50%, -50%) skew(-8deg);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Background Styles */
#app-background #background-bar {
  position: absolute; 
  z-index: -1;
  width: 100vw;
  height: 30vh;
  top: 50%;
  transform: translateY(-50%);
  border-top: 2vh solid var(--black);
  border-bottom: 2vh solid var(--black);
  background: linear-gradient(90deg, #EE9D23 0%, #E40505 100%);
}

#app-background #background-img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -2;
  width: 50vw;
}

/* Loading Screen */
.loading-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .loading-logo {
    animation: logo-spin infinite 3s ease;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Suggestions-Content {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 30px;
}

.Suggestions-Content > a {
  color: white;
}