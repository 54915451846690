.Char-Grid {
    width: 80%;
}

.Char-Img {
    width: 100%;
}

.Omit {
    filter: brightness(0.6);
}

.column {
    float: left;
    width: 6.5%;
    border: 1px solid black;
    background-color: #FFEBC6;
}
  
.char-card {
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

/* MOBILE CSS */
@media screen and (max-width: 767px) {
    .column {
        float: left;
        width: 16%;
        border: 1px solid black;
        background-color: #FFEBC6;
    }

    .Char-Grid {
        width: 100%;
    }
    
}

/* TABLET CSS */
@media screen and (min-width: 768px) and (max-width: 1366px) {
    .column {
        width: 7.45%
    }
}

/* WIDE DESKTOP CSS */
@media screen and (min-width: 1850px) {
    .column {
        width: 5.5%
    }
}
